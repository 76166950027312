<template>
  <div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">齐料分析列表</div>
        <div class="contentTopRight">
          <el-input placeholder="" suffix-icon="el-icon-search" size="small" v-model="input1" @change="searchHandler1">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData1" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="showId" label="序号" fixed="left" width="100">
        </el-table-column>
        <el-table-column prop="productionNo" label="投产号">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号">
        </el-table-column>
        <el-table-column prop="productName" label="产品名称">
        </el-table-column>
        <el-table-column prop="partCode" label="图号">
        </el-table-column>
        <el-table-column prop="version" label="版本号">
        </el-table-column>
        <el-table-column prop="plannedQuantity" label="计划数量">
        </el-table-column>
        <el-table-column prop="materialPreparedDate" label="齐料日期">
        </el-table-column>
        <el-table-column prop="materialPreparedQuantity" label="齐料数量">
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total1 }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange1" background @current-change="handleCurrentChange1"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange1
            layout="prev, pager, next,sizes, jumper" :total=total1>
          </el-pagination> </span>
      </div>
    </div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopTitle">欠料表</div>
        <div class="contentTopRight contentTopRight2">
          <el-tooltip class="item" effect="dark" content="导出" placement="top">
          <img src="@/assets/images/plan/analysis/derive.png" @click="derive" alt="" class="img">
        </el-tooltip>
          <el-input placeholder="" suffix-icon="el-icon-search" size="small" v-model="input2" @change="searchHandler2">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData2" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="65">
        </el-table-column>
        <el-table-column prop="showId" label="序号" fixed="left" width="100">
        </el-table-column>
        <el-table-column prop="materialName" label="物料名称">
        </el-table-column>
        <el-table-column prop="materialCode" label="物料编码">
        </el-table-column>
        <el-table-column prop="requiredQuantity" label="需求数量">
        </el-table-column>
        <el-table-column prop="planQuantity" label="计划数量">
        </el-table-column>
        <el-table-column prop="materialUseDate" label="用料日期">
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total2 }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange2" background @current-change="handleCurrentChange2"
            :current-page="currentPage2" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange2
            layout="prev, pager, next,sizes, jumper" :total=total2>
          </el-pagination> </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getBom1Data, getBom2Data, searchBom1Data, searchBom2Data, deriveBom2Data } from '@/api/plan.js'
export default {
  name: "production",
  data() {
    return {
      tableData1: [],
      tableData2: [],
      currentPage1: 1,
      currentPage2: 1,
      input1: '',
      input2: '',
      section: [
        '正式',
        '意向'
      ],
      tempData1: [],
      tempData2: [],
      multipleSelection: [],
      pageChange1: 10,
      pageChange2: 10,
      total1: 0,
      total2: 0,
      searchStatus1: false,
      searchStatus2: false,
      searchData1: [],
      searchData2: [],
      tempTotal1: null,
      tempTotal2: null
    }
  },
  methods: {
    // 导出
    derive() {
      let ids = []
      this.multipleSelection.forEach(item => {
        ids.push(item.showId)
      })
      deriveBom2Data(ids).then(res => {
        this.filename = '欠料表.xlsx'
        this.url = window.URL.createObjectURL(res)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.url
        link.setAttribute('download', this.filename)
        document.documentElement.appendChild(link)
        link.click()
        document.documentElement.removeChild(link)
      })
    },
    // 分页组件--一页显示多少条数据
    async handleSizeChange1(val) {
      this.pageChange1 = val
      if (this.searchStatus1) {
        this.tempData1 = this.searchData1.filter((v, i) => i < val)
      } else {
        const res = await getBom1Data(1, val)
        const { records } = res.data
        this.tableData1 = records
        this.tempData1 = this.tableData1
      }
      this.currentPage1 = 1

    },
    // 分页组件--选择页数
    async handleCurrentChange1(val) {
      if (this.searchStatus1) {
        this.tempData1 = this.searchData1.slice(val * this.pageChange1 - this.pageChange1, val * this.pageChange1)
      } else {
        const res = await getBom1Data(val, this.pageChange1)
        const { records } = res.data
        this.tableData1 = records
        this.tempData1 = this.tableData1
      }
      this.currentPage1 = val
    },
    // 分页组件--一页显示多少条数据
    async handleSizeChange2(val) {
      this.pageChange2 = val
      if (this.searchStatus2) {
        this.tempData2 = this.searchData2.filter((v, i) => i < val)
      } else {
        const res = await getBom2Data(1, val)
        const { records } = res.data
        this.tableData2 = records
        this.tempData2 = this.tableData2
      }
      this.currentPage1 = 1
    },
    // 分页组件--选择页数
    async handleCurrentChange2(val) {
      if (this.searchStatus2) {
        this.tempData2 = this.searchData2.slice(val * this.pageChange2 - this.pageChange2, val * this.pageChange2)
      } else {
        const res = await getBom2Data(val, this.pageChange2)
        const { records } = res.data
        this.tableData2 = records
        this.tempData2 = this.tableData2
      }
      this.currentPage2 = val
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 齐料分析列表搜索
    async searchHandler1() {
      const res = await searchBom1Data(1, this.tempTotal1, this.input1)
      this.searchStatus1 = true
      this.pageChange1 = 10
      this.currentPage1 = 1
      const { records, total } = res.data
      this.searchData1 = records
      this.total1 = total
      this.tempData1 = this.searchData1.filter((v, i) => i < 10)
    },
    // 齐料分析欠料表搜索
    async searchHandler2() {
      const res = await searchBom2Data(1, this.tempTotal2, this.input2)
      this.searchStatus2 = true
      this.pageChange2 = 10
      this.currentPage2 = 1
      const { records, total } = res.data
      this.searchData2 = records
      this.total2 = total
      this.tempData2 = this.searchData2.filter((v, i) => i < 10)
    },
  },
  async activated() {
    // 齐料分析表
    const res = await getBom1Data(1, 10)
    this.tableData1 = res.data.records
    this.total1 = this.tempTotal1 = res.data.total
    // 欠料表
    const res2 = await getBom2Data(1, 10)
    this.tableData2 = res2.data.records
    this.total2 = this.tempTotal2 = res2.data.total

    this.tempData1 = this.tableData1
    this.tempData2 = this.tableData2
    this.searchStatus1 = this.searchStatus2 = false

    this.input1 = this.input2 = ''
    this.pageChange1 = this.pageChange2 = 10
  }
}
</script>

<style lang="sass" scoped>
@import '../scheduling/style/style.scss'
</style>

